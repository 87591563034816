<script setup lang="ts">
import { ref, watchEffect } from 'vue';
import SectionHeader from './SectionHeader.vue';

const headerLoaded = ref<boolean>(false);
const imgLoaded = ref<boolean>(false);

const emit = defineEmits(['loaded']);

watchEffect(() => {
    if (headerLoaded.value && imgLoaded.value) {
        emit('loaded');
    }
});
</script>
<template>
    <div>
        <SectionHeader id="offer" text="Mein Angebot für dich" @loaded="headerLoaded = true" />
        <div class="container">
            <div class="text">

                <p>Energie- und Bewusstseinsarbeit als auch Körperorientierte Energiearbeit fördern deine Gesundheit und
                    bringen mehr Erfüllung, Glück, Leichtigkeit und Freude in dein Leben. Sie wirken unterstützend bei:
                </p>
                <ul class="list">
                    <li>Lern- und Konzentrationsschwierigkeiten</li>
                    <li>Ängsten/Phobien</li>
                    <li>Problemen bei Zielumsetzungen oder Neuorientierung privat oder beruflich</li>
                    <li>Schlafproblemen</li>
                    <li>Überlastung im Alltag mit Haushalt, Job, Kindern und Partnerschaft</li>
                    <li>Gelenksbeschwerden (auch bei künstlichen Gelenken)</li>
                    <li>Verdauungsproblemen</li>
                    <li>Menstruationsbeschwerden</li>
                    <li>Fehlstellungen wie z.B. Beckenschiefstand</li>
                    <li>Stoffwechselproblemen</li>
                    <li>Kreislaufproblemen</li>
                    <li>Hormonschwankungen</li>
                    <li>Stärkung des Immunsystems </li>
                    <li>und vielem mehr</li>
                </ul>
            </div>
            <div class="image wider">
                <div class="imageRelativeContainer">
                    <img src="../assets/angebot.jpg"
                        alt="Doris Böck behandelt einen Person die auf einer Liege sitzt während sie hinter der Person steht."
                        @load="imgLoaded = true" />
                    <div class="blob image1" />
                    <div class="blob image2" />
                    <div class="blob image3" />
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.blob {
    background-color: #48495d;
}

.blob.image1 {
    left: -40px;
    top: 215px;
}

.blob.image2 {
    left: 70px;
    top: -15px;
}

.blob.image3 {
    bottom: -70px;
    right: -10px;
}

li {
    padding-top: 10px;
}

.image.wider {
    width: 400px;
    align-content: center;
}

.image.wider img {
    max-width: 400px;
}

@media screen and (max-width: 850px) {
    .image.wider {
        width: 100% !important;
        text-align: center;
        margin-top: 18px;
    }

    .image.wider img {
        width: 100% !important;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }

    .blob.image3 {
        bottom: -50px;
    }
}
</style>