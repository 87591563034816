<script setup lang="ts">
import { ref, watchEffect } from 'vue';
import SectionHeader from './SectionHeader.vue';

const headerLoaded = ref<boolean>(false);
const imgLoaded = ref<boolean>(false);

const emit = defineEmits(['loaded']);

watchEffect(() => {
    if (headerLoaded.value && imgLoaded.value) {
        emit('loaded');
    }
});
</script>
<template>
    <div>
        <SectionHeader id="contact" text="Kontakt" @loaded="headerLoaded = true" />
        <div class="container">
            <div class="text">
                <a href="https://maps.app.goo.gl/ePh5XsdLah2cnpHG7" target="_blank">
                    <p>
                        LEBENSKRAFT
                    </p>
                    <p>Doris Böck</p>
                    <p>Praxis für Energie- und Bewusstseinsarbeit</p>
                    <p>
                        Pesenbach 12
                    </p>
                    <p>4101 Feldkirchen</p>
                </a>
                <br>
                <p>Email: <a href="mailto:doris@lebenskraft-boeck.at">doris@lebenskraft-boeck.at</a></p>
                <p>Telefon: <a href="tel:+436648280270">+43664 8280270</a></p>
            </div>
            <div class="image wider">
                <div class="imageRelativeContainer">
                    <a href="https://maps.app.goo.gl/ePh5XsdLah2cnpHG7" target="_blank">
                        <img src="../assets/maps.png"
                            alt="Ein Ausschnitt von Google Maps in dem der Weg zur Adresse von Doris Böck markiert ist."
                            @load="imgLoaded = true" />
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="costs">
            <!-- TODO: add image of google maps with link -->
            <div>
                <p>Behandlungen für Kinder (max. 45 Minuten)</p>
                <p class="price">40,00 Euro</p>
            </div>
            <div>
                <p>Behandlungen für Erwachsene</p>
                <p class="price">65,00 Euro</p>
            </div>
            <div>
                <p>Fernbehandlungen</p>
                <p class="price">50,00 Euro</p>
            </div>
        </div>
    </div>
</template>
<style scoped>
.text {
    flex-grow: 1;
}

a {
    text-decoration: none;
    color: #2c3e50;
    cursor: pointer;
}

.costs div {
    display: flex;
    gap: 32px;
    margin-top: 8px;
}

.costs div .price {
    margin-left: auto;
    flex-shrink: 0;
}

@media screen and (max-width: 950px) {
    .container {
        display: block;
    }

    .costs {
        margin-top: 16px;
    }
}

.image.wider {
    width: 400px;
    align-content: center;
}

.image.wider img {
    max-width: 350px;
}

@media screen and (max-width: 950px) {
    .container {
        display: block;
    }

    .text {
        width: 100%;
    }

    .image.wider {
        width: 100% !important;
        text-align: center;
        margin-top: 18px;
    }

    .image.wider img {
        width: 100% !important;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }

    .costs div {
        gap: 16px;
    }
}
</style>