<script setup lang="ts">
import { ref, watchEffect } from 'vue';
import SectionHeader from './SectionHeader.vue';

const imgLoaded = ref<boolean>(false);
const headerLoaded = ref<boolean>(false);

const emit = defineEmits(['loaded']);

watchEffect(() => {
    if (imgLoaded.value && headerLoaded.value) {
        emit('loaded');
    }
});
</script>
<template>
    <div style="padding-top: 32px;">
        <SectionHeader id="aboutMe" text="Über mich" @loaded="headerLoaded = true" />
        <div class="container">
            <div class="text">
                <p>
                    Ich lebe mit meiner Familie in Pesenbach nicht weit vom wunderschönen Pesenbachtal und bin Mutter
                    einer wundervollen Tochter.</p>
                <p>
                    Beruflich habe ich nach einer Lehre als Bürokauffrau 9 Jahre im Büro gearbeitet. In mir wuchs der
                    Wunsch nach einer Tätigkeit, in der ich Menschen unterstützen kann. Daher beschloss ich 2012 die
                    Ausbildung zur diplomierten Kinderkrankenschwester zu machen. Ich sammelte wunderbare Erfahrungen in
                    der Pflege und Betreuung der Kinder und Eltern.</p>
                <p>
                    Durch meine Ausbildungen in Energie- und Bewusstseinsarbeit merkte ich jedoch, dass ich über diesen
                    Zugang mehr bewirken und mich besser entfalten kann. Mit meinem Fachwissen als
                    Kinderkrankenschwester und meinen Fähigkeiten als Energetikerin ist es mein Ziel die Menschen ein
                    Stück auf ihrem Weg zu begleiten und mit ihrer Bereitschaft mehr Gleichgewicht in allen Lebenslagen
                    zu erreichen, sodass ihre LEBENSKRAFT mehr zum Ausdruck kommen kann.
                </p>
            </div>
            <div class="image">
                <div class="imageRelativeContainer">
                    <img src="../assets/ueber_mich.jpg" alt="Foto von Doris Böck in einer Wald-Umgebung"
                        @load="imgLoaded = true" />
                    <div class="blob image1" />
                    <div class="blob image2" />
                </div>
            </div>
            <div class="blob blob1" />
            <div class="blob blob2" />
        </div>
    </div>
</template>
<style scoped>
.blob {
    background-color: #bfe9f4;
}

.blob.blob1 {
    left: -60px;
    top: 100px;
}

.blob.blob2 {
    top: -40px;
    right: -85px;
}

.blob.image1 {
    top: -10px;
    left: -40px;
}

.blob.image2 {
    bottom: -30px;
    right: 60px;
}
</style>