<script setup lang="ts">
import { ref } from 'vue';
import SlideUpDown from 'vue-slide-up-down';
import ArrowDown from './ArrowDown.vue';

const isOpen = ref<boolean>(false);
</script>
<template>
    <div class="dropdown">
        <img src="../assets/Logo_Square.png" />
        <div class="dropdownHeading">
            <slot name="heading" />
        </div>
        <button :aria-label="isOpen ? 'Schließen' : 'Öffnen'" @click="isOpen = !isOpen">
            <div class="arrowContainer" :class="{
                'open': isOpen,
                'closed': !isOpen,
            }">
                <ArrowDown />
            </div>
        </button>
    </div>
    <SlideUpDown :active="isOpen">
        <div class="dropdownContent">
            <slot name="content" />
        </div>
    </SlideUpDown>
</template>
<style scoped>
img {
    width: 48px;
    margin-right: 32px;
}

button {
    background-color: transparent;
    border: none;
    font-size: 24px;
    margin-left: auto;
    margin-right: 12px;
    cursor: pointer;
}

.arrowContainer {
    margin-top: 8px;
    transition: all 0.5s ease;
}

.arrowContainer.open {
    transform: rotate(180deg);
    margin-top: 0px;
}

.dropdown {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #e1dbd2;
    padding: 8px;
    background-color: #f1f2f6;
}

.dropdownContent {
    padding: 8px;
}

.dropdownHeading {
    font-weight: bold;
}

@media screen and (max-width: 850px) {
    img {
        margin-right: 16px;
    }
}
</style>