<script setup lang="ts">
import { ref } from 'vue';

import BurgerMenu from './BurgerMenu.vue';

const activeItemIdx = ref<number>(0);
const menuList = ref<HTMLElement | null>(null);
const mobileMenuOpen = ref<boolean>(false);

const scrollTo = (elementId: string): void => {
    let targetId = '';
    if (elementId === 'aboutMeMenu') targetId = 'aboutMe';
    else if (elementId === 'myWorkMenu') targetId = 'myWork';
    else if (elementId === 'offerMenu') targetId = 'offer';
    else if (elementId === 'contactMenu') targetId = 'contact';
    if (targetId) {
        const scrollTarget = document.getElementById(targetId);
        if (scrollTarget) {
            mobileMenuOpen.value = false;
            scrollTarget.scrollIntoView({ behavior: 'smooth' });
        }
    }
};

const onItemKeydown = (evt: KeyboardEvent): void => {
    if (evt.key === 'Enter' || evt.key === ' ') {
        const target = evt.target;
        if (target) {
            scrollTo((target as HTMLElement).id);
            return;
        }
    }
    let newIdx = activeItemIdx.value;
    if (menuList.value) {
        const items = menuList.value.querySelectorAll('li');
        if (evt.key === 'ArrowLeft' || evt.key === 'ArrowUp') {
            newIdx = activeItemIdx.value - 1;
            if (newIdx < 0) newIdx = items.length - 1;
        } else if (evt.key === 'ArrowRight' || evt.key === 'ArrowDown') {
            newIdx = activeItemIdx.value + 1;
            if (newIdx >= items.length) newIdx = 0;
        }
        if (newIdx !== activeItemIdx.value && menuList.value) {
            const item = menuList.value.querySelectorAll('li')[newIdx];
            item.focus();
            activeItemIdx.value = newIdx;
            evt.preventDefault();
        }
    }
};
</script>
<template>
    <div class="mobileMenuBar">
        <button class="mobileButton" @click="mobileMenuOpen = !mobileMenuOpen">
            <BurgerMenu alt="Menü öffnen" class="burgerIcon" />
        </button>
    </div>
    <div class="menuContainer" :class="{
        'isOpen': mobileMenuOpen
    }">
        <ul class="menuList" ref="menuList" role="menu" tabindex="-1" aria-label="Navigationsmenü">
            <li id="aboutMeMenu" @click="scrollTo('aboutMeMenu')" @keydown="onItemKeydown($event)"
                :tabindex="activeItemIdx === 0 ? '0' : '-1'" role="menuitem">
                <span>Über mich</span>
            </li>
            <li id="offerMenu" @click="scrollTo('offerMenu')" @keydown="onItemKeydown($event)"
                :tabindex="activeItemIdx === 1 ? '0' : '-1'" role="menuitem">
                <span>Angebot</span>
            </li>
            <li id="myWorkMenu" @click="scrollTo('myWorkMenu')" @keydown="onItemKeydown($event)"
                :tabindex="activeItemIdx === 2 ? '0' : '-1'" role="menuitem"><span>Meine
                    Arbeit</span></li>
            <li id="contactMenu" @click="scrollTo('contactMenu')" @keydown="onItemKeydown($event)"
                :tabindex="activeItemIdx === 3 ? '0' : '-1'" role="menuitem"><span>Kontakt</span>
            </li>
        </ul>
    </div>
</template>
<style scoped>
.mobileMenuBar {
    display: flex;
    display: none;
}

.mobileButton {
    margin-left: auto;
    border: none;
    cursor: pointer;
}

.burgerIcon {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 24px;
}

.mobileMenuBar,
.menuContainer {
    position: fixed;
    top: 0;
    width: 100%;
    height: 56px;
    background-color: #f1f2f6;
    color: #2c3e50;
    z-index: 20;
}

.menuContainer {
    align-content: center;
    top: 0;
    gap: 26px;
    display: flex;
}

.menuList {
    display: flex;
    justify-content: end;
    width: 100%;
    gap: 32px;
    padding: 0 48px;
}

.menuList li {
    display: inline;
    cursor: pointer;
    align-content: center;
    padding-bottom: 8px;
    height: fit-content;
}

.menuList li span {
    display: block;
}

.menuList li:hover,
.menuList li:focus {
    border-bottom: 1px solid black;
}

@media screen and (max-width: 510px) {
    .menuContainer:not(.isOpen) {
        display: none;
    }

    .menuList {
        display: block;
        padding: 0 32px;
    }

    .menuList li span {
        text-align: right;
        padding-top: 8px;
    }

    .menuContainer.isOpen {
        display: flex;
        top: 56px;
        height: fit-content;
        border-top: 1px solid gray;
    }

    .mobileMenuBar {
        display: flex;
    }
}
</style>