<template>
    <div class="footerContainer">
        <a href="/aufklaerung.html" target="_blank">
            <p>Rechtliche Aufklärung</p>
        </a>
        <a href="/impressum.html" target="_blank">
            <p>Impressum</p>
        </a>
    </div>
</template>
<style scoped>
.footerContainer {
    background-color: #f1f2f6;
    text-align: center;
    padding: 8px;
}

a {
    text-decoration: none;
    color: #2c3e50;
    cursor: pointer;
}
</style>